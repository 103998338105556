<template>
  <vue-scroll class="ps register-page payment">
    <div class="flex column">
      <div class="box mh-15 p-20 flex column gaps card-base card-shadow--large" style="max-width: 700px;">
        <h1 class="box accent-text m-0 mb-20" v-if="current.useStatus === 'booked'">Booking Confirmed</h1>
        <h3 class="box m-0 mb-10">Book details:</h3>
        <div class="box grow purchase">
          <div class="t-row flex">
              <div class="box label">Date:</div>
              <div class="box value">{{date}}</div>
          </div>
          <div class="t-row flex">
              <div class="box label">Time:</div>
              <div class="box value">{{current.session.time}}</div>
          </div>
          <div class="t-row flex">
              <div class="box label">Service:</div>
              <div class="box value">{{current.session.name}}</div>
          </div>
          <div class="t-row flex">
              <div class="box label">Tickets:</div>
              <div class="box value">{{current.ticket.persons}} {{current.ticket.name}} (AED {{current.ticket.persons*current.ticket.price}})</div>
          </div>
          <div class="t-row flex" v-if="current.addons.length > 0">
              <div class="box label">Addons:</div>
              <div class="box value">
                <div v-for="(a, index) in current.addons" :key="index + '-addons'">{{a.count}} {{a.name}} (AED {{a.cost * a.count}})</div>
              </div>
          </div>
          <div class="t-row flex">
              <div class="box label">Contact:</div>
              <div class="box value">{{current.customer.first + ' ' + current.customer.surname}}</div>
          </div>
          <div class="t-row flex" v-if="current.discount.percent > 0">
              <div class="box label">Discount:</div>
              <div class="box value">{{current.discount.percent}}%</div>
          </div>
          <div class="t-row flex">
              <div class="box label">Status:</div>
              <div class="box value">{{current.useStatus}}</div>
          </div>
          <div class="t-row tot">
              <div class="label">Amount</div>
              <div class="value">AED {{current.amount}}</div>
          </div>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NewBook',

  data () {
    return {}
  },

  computed: {
    ...mapState({
      defaultBook: state => state.booking.defaultBook
    }),
    ...mapGetters(['getBooksById']),
    current () {
      return this.getBooksById[this.$route.params.id] || this.defaultBook
    },
    date () {
      let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      return new Date(this.current.day).toLocaleString('en-GB', options)
    }
  },

  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  }
}
</script>
