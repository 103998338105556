<template>
  <vue-scroll class="ps register-page payment">
    <div class="flex column">
      <div class="box mh-15 p-20 flex column gaps card-base card-shadow--large" style="max-width: 700px;">
        <h3 class="box m-0 mb-10">Book details:</h3>
        <div class="box grow purchase">
          <div class="t-row flex">
              <div class="box label">Date:</div>
              <div class="box value">{{date}}</div>
          </div>
          <div class="t-row flex">
              <div class="box label">Time:</div>
              <div class="box value">{{current.session.time}}</div>
          </div>
          <div class="t-row flex">
              <div class="box label">Service:</div>
              <div class="box value">{{current.session.name}}</div>
          </div>
          <div class="t-row flex">
              <div class="box label">Tickets:</div>
              <div class="box value">{{current.ticket.persons}} {{current.ticket.name}} (AED {{current.ticket.persons*current.ticket.price}})</div>
          </div>
          <div class="t-row flex" v-if="current.addons.length > 0">
              <div class="box label">Addons:</div>
              <div class="box value">
                <div v-for="(a, index) in current.addons" :key="index + '-addons'">{{a.count}} {{a.name}} (AED {{a.cost * a.count}})</div>
              </div>
          </div>
          <div class="t-row flex">
              <div class="box label">Contact:</div>
              <div class="box value">{{current.customer.first + ' ' + current.customer.surname}}</div>
          </div>
          <div class="t-row flex" v-if="current.discount.percent > 0">
              <div class="box label">Discount:</div>
              <div class="box value">{{current.discount.percent}}%</div>
          </div>
          <div class="t-row flex">
              <div class="box label">Status:</div>
              <div class="box value">{{statusForPrint}}</div>
          </div>
          <div class="t-row tot">
              <div class="label">Amount</div>
              <div class="value">AED {{current.amount}}</div>
          </div>
        </div>
        <div v-if="isPending && isValidTime" class="danger-text">Payment failure. <span v-if="isRepeat">Please try another card.</span></div>
        <div v-if="isPending && !isValidTime" class="danger-text"> Not valid.(payment didn't go through).</div>
        <div class="box flex gaps">
          <div class="box right">
            <!--<el-popconfirm v-if="isRefund"
              class="mr-10"
              icon-color="#402e84"
              @confirm="onClickRefund"
              title="Are you sure you want to cancel booking and initiate refund request? Processing of the refund might take up to 14 days.">
              <el-button slot="reference" plain type="primary">Refund</el-button>
            </el-popconfirm>-->
            <el-button v-if="isEdit"
              @click="onClickChange" type="primary">Change</el-button>
            <el-popconfirm v-if="isPending"
              @confirm="onClickRemote"
              icon-color="#402e84"
              title="Are you sure to remove this?">
              <el-button class="mr-10" slot="reference" type="primary">Remove</el-button>
            </el-popconfirm>
            <el-button v-if="isRepeat && isValidTime"
              @click="onClickRepeat" plain type="primary">Try again</el-button>
          </div>
        </div>
        <i v-if="current.useStatus === 'refund'" class="mdi danger-text fs-50 refund mdi-alert-octagram">Refund request</i>
        <i v-if="current.useStatus === 'refunded'" class="mdi danger-text fs-50 refund mdi-alert-octagram">Refund completed</i>
        <form :action="hidden.PaymentPage" method="post">
          <input type='Hidden' name='TransactionID' :value="hidden.TransactionID"/>
          <input type="submit" ref='hidden' value="Submit" v-show="false">
        </form>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Book',

  data () {
    return {
      hidden: {
        TransactionID: '',
        PaymentPage: ''
      }
    }
  },

  computed: {
    ...mapState({
      defaultBook: state => state.booking.defaultBook,
      refundBooking: state => state.booking.settings.refundBooking,
      editBooking: state => state.booking.settings.editBooking,
      blockBooking: state => state.booking.settings.blockBooking,
      validTimeBooking: state => state.booking.settings.validTimeBooking
    }),
    ...mapGetters(['getBooksById']),
    current () {
      return this.getBooksById[this.$route.params.id] || this.defaultBook
    },
    statusForPrint () {
      let status = ''
      switch ((this.current.useStatus)) {
        case 'used':
          status = 'skated'
          break
        case 'refund':
          status = 'refund request'
          break
        case 'refunded':
          status = 'refund completed'
          break
        case 'pending':
          if (this.isValidTime) {
            status = 'pending'
          } else {
            status = 'not valid'
          }
          break
        case 'booked':
          if (this.isWasted) {
            status = 'expired'
          } else {
            status = 'booked'
          }
          break
      }
      return status
    },
    date () {
      let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      return new Date(this.current.day).toLocaleString('en-GB', options)
    },
    isRefund () {
      let start = new Date(this.current.day)
      start.setHours(this.current.session.time.split(' - ')[0].split(':')[0], this.current.session.time.split(' - ')[0].split(':')[1])
      let startTime = start.getTime()
      let refund = new Date(new Date().setHours(new Date().getHours() + this.refundBooking))
        .getTime()
      return startTime > refund && !['used', 'refund', 'pending'].includes(this.current.useStatus)
    },
    isEdit () {
      let start = new Date(this.current.day)
      start.setHours(this.current.session.time.split(' - ')[0].split(':')[0], this.current.session.time.split(' - ')[0].split(':')[1])
      let startTime = start.getTime()
      let edit = new Date(new Date().setHours(new Date().getHours() + this.editBooking))
        .getTime()
      return startTime > edit && !['used', 'refund', 'pending'].includes(this.current.useStatus)
    },
    isPending () {
      return this.current.useStatus === 'pending'
    },
    isValidTime () {
      return (new Date(this.current.createDate).getTime() + (this.validTimeBooking * 1000) - new Date().getTime()) > 0
    },
    isRepeat () {
      let now = new Date()
      let today = now.toISOString().slice(0, 10)
      let startArr = this.current.session.time.split(' - ')[0].split(':')
      let start = new Date(now.setHours(startArr[0], startArr[1], 0)).getTime()
      let nowWithDelay = new Date(new Date().setMinutes(new Date().getMinutes() + this.blockBooking)).getTime()
      return this.isPending && (this.current.day !== today || start > nowWithDelay)
    },
    isWasted () {
      let start = new Date(this.current.day + ' ' + this.current.session.time.split(' - ')[0])
        .getTime()
      let now = new Date()
        .getTime()
      return start < now
    }
  },

  methods: {
    ...mapActions(['refundBook', 'remoteBook', 'repeatBook', 'setSplashScreen']),
    onClickRefund () {
      this.$store.commit('setSplashScreen', true)
      this.refundBook({ TransactionID: this.current.TransactionID })
        .then(() => {
          this.$store.commit('setSplashScreen', false)
        })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Refund request initiated'
          })
        })
        .catch(err => {
          this.$message({
            showClose: true,
            message: 'Error ' + err.message,
            type: 'error'
          })
        })
    },
    onClickChange () {
      this.$onCommandParams('change', { id: this.current._id })
    },
    onClickRemote () {
      this.remoteBook({ id: this.current._id })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Book removed successfully',
            type: 'success'
          })
        })
        .then(() => {
          this.$onCommandParams('mybooks')
        })
    },
    onClickRepeat () {
      this.setSplashScreen(true)
        .then(() => {
          return this.repeatBook({ id: this.current._id })
        })
        .then(response => {
          this.hidden = response
        })
        .then(() => {
          this.$refs.hidden.click()
        })
        .catch(() => {
          this.$onCommandParams('error')
        })
    }
  },

  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  }
}
</script>
